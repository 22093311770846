























































































































































































































































































































































import Base from '@/mixins/Base.vue';

import { IClient } from '@/interfaces/client';
import { IRole } from '@/interfaces/role';
import { IUser, IUsersResponse } from '@/interfaces/user';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({

    data() {
        return {
            params: {
                active: null,
                name: '',
                sortBy: 'id',
                orderBy: 'asc',
                role: null as IRole | any,
                client: null as IClient | any,
                page: 1,

            },

            users: {
                data: [] as Array<IUser>,
                meta: {} as IPagination | any,
            } as IUsersResponse,
        };
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        getUsers(page: number = 1) : void {
            this.params.page = page;

            this.get<IUsersResponse>('users', {
                params: {
                    ...this.params,
                    role: this.params.role ? this.params.role.name : null,
                    client_id: this.params.client ? this.params.client.id : null,
                },
            }).then(({ data }) => {
                this.users = data;
            });
        },

    },
});

export default component;
